<template>
  <v-card color="transparent" tile elevation="0">
    <v-card class="py-sm-8 py-4" color="light_gray" tile elevation="0">
      <v-row justify="center" no-gutters>
        <v-col cols="11" lg="9">
          <span class="text-sm-h5 text-body-1 font-weight-bold">{{ $t(`label.myAccount`) }}</span>
        </v-col>
      </v-row>
    </v-card>
    <v-row justify="center" class="my-8" no-gutters>
      <v-col cols="11" lg="9">
        <div class="d-block d-md-flex mb-6">
          <v-col cols="12" md="3">
            <v-card class="pa-6 rounded-lg mb-6" color="transparent" tile elevation="3">
              <div class="font-weight-bold text-body-1 mb-2">{{ $t(`label.accountSetting`) }}</div>
              <div class="pl-4">
                <div class="py-2">
                  <v-btn
                    :to="{ name: routeName.ACCOUNT }"
                    exact-active-class="font-weight-bold unbox_primary--text"
                    class="text-capitalize pa-0 mx-0 v-align-initial"
                    height="auto"
                    text
                    plain
                    :ripple="false"
                  >
                    <span class="text-body-2 px-0">{{ $t(`label.accountInfo`) }}</span>
                  </v-btn>
                </div>
                <div class="py-2">
                  <v-btn
                    :to="{ name: routeName.CHANGE_PASSWORD }"
                    exact-active-class="font-weight-bold unbox_primary--text"
                    class="text-capitalize pa-0 mx-0 v-align-initial"
                    height="auto"
                    text
                    plain
                    :ripple="false"
                  >
                    <span class="text-body-2 px-0">{{ $t(`label.changePassword`) }}</span>
                  </v-btn>
                </div>
                <div class="py-2">
                  <v-btn
                    :to="{ name: routeName.DELIVERY_ADDRESSES }"
                    exact-active-class="font-weight-bold unbox_primary--text"
                    class="text-capitalize pa-0 mx-0 v-align-initial"
                    height="auto"
                    text
                    plain
                    :ripple="false"
                  >
                    <span class="text-body-2 px-0">{{ $t(`label.deliveryInformation`) }}</span>
                  </v-btn>
                </div>
                <div class="py-2">
                  <v-btn
                    :to="{ name: routeName.DELETE_ACCOUNT }"
                    exact-active-class="font-weight-bold unbox_primary--text"
                    class="text-capitalize pa-0 mx-0 v-align-initial"
                    height="auto"
                    text
                    plain
                    :ripple="false"
                  >
                    <span class="text-body-2 px-0">{{ $t(`label.deleteAccount`) }}</span>
                  </v-btn>
                </div>
              </div>
            </v-card>
          </v-col>
          <v-col cols="12" md="8">
            <div><router-view></router-view></div>
          </v-col>
        </div>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { CUSTOMER_GET_INFORMATION } from '@/store/customer.module'
import { SESSION } from '@/constants'
import { ROUTE_NAME } from '@/constants/route.constants'
export default {
  name: 'profileLayout',
  components: {},
  data: () => ({
    routeName: ROUTE_NAME
  }),
  created() {
    this.getCustomerInformation()
  },
  methods: {
    getCustomerInformation() {
      let data = {
        uuid: localStorage.getItem(SESSION.CUSTOMER_UUID)
      }
      this.$store.dispatch(CUSTOMER_GET_INFORMATION, { data })
    }
  }
}
</script>

<style lang="scss" scoped>
.profileMenu-container {
  width: 250px;
}

.profile-container-table td {
  vertical-align: top;
}
</style>
